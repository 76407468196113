import React, { useState, useRef, useLayoutEffect, useCallback } from 'react';
import atomize from "@quarkly/atomize";
import debounce from "debounce";

const Youtube = ({
	videoId,
	width,
	height,
	ratio = 1.5,
	fullWidth,
	...props
}) => {
	const ref = useRef();
	const [size, setSize] = useState([width, height]);
	const onWResize = useCallback(() => {
		let clientWidth = 0;
		if (ref && ref.current) clientWidth = ref.current.clientWidth;
		const height = Math.round(clientWidth / ratio);
		setSize([`${clientWidth}px`, `${height}px`]);
	}, [ref]);
	const onWindowResize = debounce(onWResize, 500);
	useLayoutEffect(() => {
		if (!fullWidth) window.addEventListener('resize', onWindowResize, true);
		return () => {
			window.removeEventListener('resize', onWindowResize);
		};
	}, [onWindowResize]);
	useLayoutEffect(() => {
		if (!fullWidth && ref && ref.current) {
			ref.current.style.width = size[0];
			ref.current.style.height = size[1];
		}
	}, [ref, size]);
	return <iframe
		ref={ref}
		width={size[0]}
		height={size[1]}
		src={`https://www.youtube.com/embed/${videoId}?controls=0`}
		title=""
		frameborder="0"
		allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
		{...props}
		allowfullscreen
	></iframe>;
};

export default atomize(Youtube)({
	name: "Youtube",
	normalize: true,
	mixins: true,
	description: {
		en: "Youtube — embed video"
	},
	propInfo: {
		videoId: {
			control: "input",
			category: 'Main'
		},
		ratio: {
			control: "input",
			category: 'Main'
		},
		fullWidth: {
			title: 'Full Width',
			description: {
				en: 'Full width with keep aspect ratio',
				ru: 'на всю ширину с сохранением пропорций'
			},
			control: 'checkbox',
			type: 'boolean',
			category: 'Main'
		}
	}
});