import React, { useRef, useLayoutEffect } from "react";
import atomize from "@quarkly/atomize";
const isDev = typeof window !== 'undefined' && window.QAPI && window.QAPI.mode === 'development'; // Put your HTML here:

const customHtml = `
<div data-mobile-view="true" data-share-size="20" data-like-text-enable="false" data-background-alpha="0.0" data-pid="1903100" data-mode="share" data-background-color="#ffffff" data-share-shape="rectangle" data-share-counter-size="12" data-icon-color="#ffffff" data-mobile-sn-ids="fb.vk.tw.ok.wh.tm.vb." data-text-color="#000000" data-buttons-color="#FFFFFF" data-counter-background-color="#ffffff" data-share-counter-type="common" data-orientation="horizontal" data-following-enable="false" data-sn-ids="fb.vk.tw.ok.wh.tm.vb.em.ln." data-preview-mobile="false" data-selection-enable="false" data-exclude-show-more="false" data-share-style="1" data-counter-background-alpha="1.0" data-top-button="false" class="uptolike-buttons" ></div>
`;

const UpToLike = ({
	children,
	...props
}) => {
	const ref = useRef(null);
	useLayoutEffect(() => {
		const head = document?.head;

		if (!isDev) {
			ref.current.innerHTML = customHtml;
			const script = document.createElement("script");
			script.src = "https://w.uptolike.com/widgets/v1/uptolike.js";
			head.appendChild(script);
		}
	}, []);
	return <div {...props} ref={ref}>
		UpToLike placeholder
	</div>;
};

export default atomize(UpToLike)({
	name: "UpToLike",
	normalize: true,
	mixins: true
});