// import React from 'react';
import atomize from "@quarkly/atomize";
import NoCopy from "quarkly-nocopy";
export default atomize(NoCopy)({
	name: "Nocopy",
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	description: {
		en: "Nocopy"
	}
});