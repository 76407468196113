import React, { useRef, useLayoutEffect } from "react";
import atomize from "@quarkly/atomize"; // Put your HTML here:

const AnimationBgHTML = `
	<style>	
.custom-bg {
    background: linear-gradient(139deg, #293ee8, #57aca2, #701fbf);
    background-size: 600% 600%;
}

.custom-anim-bg {
    -webkit-animation: AnimationName 19s ease infinite;
    animation: AnimationName 19s ease infinite;
}

@-webkit-keyframes AnimationName {
    0%{background-position:29% 0%}
    50%{background-position:72% 100%}
    100%{background-position:29% 0%}
}
@keyframes AnimationName {
    0%{background-position:29% 0%}
    50%{background-position:72% 100%}
    100%{background-position:29% 0%}
}

.textbg {
	background: -webkit-linear-gradient(#eee, #aee);
  background-size: 600% 600%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  
  -webkit-animation: AnimationName 10s ease infinite;
  animation: AnimationName 10s ease infinite;
}
	</style>
`;

const AnimationBg = ({
	children,
	className,
	...props
}) => {
	const ref = useRef(null);
	useLayoutEffect(() => {
		ref.current.innerHTML = AnimationBgHTML;
	}, []);
	console.log(props);
	return <>
		<div ref={ref} />
		<div className={`custom-wrapper custom-bg custom-anim-bg ${className}`} {...props}>
			{children}
		</div>
	</>;
};

export default atomize(AnimationBg)({
	name: "AnimationBg",
	normalize: true,
	mixins: true
});