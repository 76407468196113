import React, { useRef, useEffect, useLayoutEffect, useCallback } from 'react';
import atomize from "@quarkly/atomize";
const customHTML = `
<style>
  .custom-video {
    overflow: hidden;
    pointer-events: none;
  }
  
  .custom-video__video {
    position: absolute;
    z-index: 888;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    height: auto;
    object-fit: cover;
  }

</style>
`;

const VideoBg = ({
	className,
	...props
}) => {
	const container = useRef(null);
	const ref = useRef(null);
	const video = useRef(null);
	useLayoutEffect(() => {
		ref.current.innerHTML = customHTML;
	}, [ref]);
	const loadVideo = useCallback(() => {
		// for chrome bug
		if (window.navigator.userAgent.match(/Chrome[\s/]\d+/)) try {
			fetch('https://prod.help/files/consulting.mp4').then(r => {
				if (!r) return null;
				r.blob().then(blob => {
					if (!blob) return null;
					video.current.src = window.URL.createObjectURL(blob);
					video.current.play();
				});
			});
		} catch (e) {
			video.current.src = 'https://prod.help/files/consulting.mp4';
			video.current.play();
		}
	}, [video]);
	useEffect(() => {
		loadVideo();
	}, [loadVideo]);
	return <div ref={container} {...props} className={`custom-video ${className}`}>
		      
		<div ref={ref} style={{
			display: 'none'
		}}></div>
		      
		<video
			ref={video}
			poster="https://prod.help/files/poster.jpg"
			className='custom-video__video'
			muted
			loop
			autoPlay
			playsInline
		>
			        
			<source src="https://prod.help/files/consulting.mp4" type='video/mp4' />
			      
		</video>
		    
	</div>;
};

export default atomize(VideoBg)({
	name: "VideoBg",
	normalize: true,
	mixins: true,
	description: {
		en: "VideoBg"
	},
	propInfo: {// past here props description for your component
		// yourCustomProps: {
		//   control: "input"
		// }
	}
});