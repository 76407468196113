import React from "react";
import theme from "theme";
import { Theme, Link, Text, Section, Box, Hr, Span, Strong, Image, List, Input, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, Formspree } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Владимир Алексеев – digital консультант
			</title>
			<meta name={"description"} content={"Консультант в сферах: веб-разработка, мобильная разработка, интернет-маркетинг"} />
			<meta property={"og:title"} content={"Владимир Алексеев – digital консультант"} />
			<meta property={"og:description"} content={"Консультант в сферах: веб-разработка, мобильная разработка, интернет-маркетинг"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/604fb33c8db767001e87d6c0/images/opengraph.jpg?v=2021-04-07T15:53:43.892Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/604fb33c8db767001e87d6c0/images/favicon-32x32.png?v=2021-04-05T19:13:11.302Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/604fb33c8db767001e87d6c0/images/apple-touch-icon.png?v=2021-04-05T19:13:28.968Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/604fb33c8db767001e87d6c0/images/apple-touch-icon.png?v=2021-04-05T19:13:28.968Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/604fb33c8db767001e87d6c0/images/apple-touch-icon.png?v=2021-04-05T19:13:28.968Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/604fb33c8db767001e87d6c0/images/apple-touch-icon.png?v=2021-04-05T19:13:28.968Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/604fb33c8db767001e87d6c0/images/mstile-150x150.png?v=2021-04-05T19:13:48.534Z"} />
			<meta name={"msapplication-TileColor"} content={"#000000"} />
		</Helmet>
		<Components.TagManager />
		<Components.AnimationBg>
			<Box
				quarkly-title="Intro header"
				display="flex"
				flex-direction="row"
				sm-display="flex"
				sm-flex-direction="column"
			>
				<Section flex="6">
					<Override slot="SectionContent" font="--headline1" max-width="calc( (var(--qtheme-cmp-section-maxWidth-default) - 40vw) )" width="50vw" />
					<Text font="--headline1" as="h1">
						Консультант в сферах:
					</Text>
					<Text font="--headline2" as="h2">
						Веб-разработка
					</Text>
					<Text font="--headline2" as="h2">
						Мобильная разработка
					</Text>
					<Text font="--headline2" as="h2">
						Интернет-маркетинг
					</Text>
				</Section>
				<Box
					width="100%"
					lg-width="400px"
					md-width="300px"
					sm-height="350px"
					sm-width="auto"
					flex="4"
					background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/604fb33c8db767001e87d6c0/images/opengraph.jpg?v=2021-04-07T15:53:43.892Z) center/cover no-repeat scroll padding-box"
				>
					<Components.VideoBg position="relative" min-height="100%" height="auto" min-width="100%" />
				</Box>
			</Box>
		</Components.AnimationBg>
		<Hr background="--color-gradient2" margin="0 0px 0 0px" padding="0px 0px 2px 0px" display="none" />
		<Section quarkly-title="Кто я">
			<Box max-width="750px">
				<Text as="h3" font="--headline3">
					Меня зовут Владимир Алексеев.
				</Text>
				<Text font="--base" margin="0 0px 16px 0px">
					Я занимаюсь веб-разработкой{" "}
					<Span
						background="--color-gradient2"
						overflow-wrap="normal"
						word-break="normal"
						white-space="nowrap"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
					>
						с 2004 года
					</Span>
					{" "}(
					<Link
						rel="nofollow"
						href="https://www.fl.ru/users/alexvladimir/info/#contacts_info_block"
						target="_blank"
						color="--dark"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
					>
						один из первых профилей на фрилансе
					</Link>
					).
					<br />
					За 16 лет я побывал в разных ролях, на разных должностях и попробовал самые разные языки, фреймворки, технологии, платформы.{" "}
					<br />
					Опыт в веб-разработке{" "}
					<Strong>
						больше 40 000 часов!
					</Strong>
				</Text>
			</Box>
		</Section>
		<Section quarkly-title="Чем полезен">
			<Override slot="SectionContent" flex-direction="row" sm-display="block" />
			<Box sm-display="none">
				<Image src="https://uploads.quarkly.io/604fb33c8db767001e87d6c0/images/w_4ec981f9.jpg?v=2021-03-29T19:31:39.822Z" max-height="100%" max-width="100%" height="300px" />
			</Box>
			<Box margin="-4px 0px 0px 20px" sm-margin="-4px 0px 0px 0">
				<Text as="h2" font="--headline2" margin="0 0px 5px 0px">
					Чем могу помочь
				</Text>
				<List margin="0 0px 16px 0px">
					<Text margin="0 0px 10px 0px" font="--base">
						выбор технологий и платформ для реализации digital проекта;
					</Text>
					<Text margin="0 0px 10px 0px" font="--base">
						разрешение конфликтов и споров между заказчиком и исполнителем;
					</Text>
					<Text margin="0 0px 10px 0px" font="--base">
						протокол осмотра сайта (в том числе для предоставления в суд);
					</Text>
					<Text margin="0 0px 10px 0px" font="--base">
						аудит качества проекта;
						<br />
					</Text>
					<Text margin="0 0px 10px 0px" font="--base">
						помощь в найме;
					</Text>
					<Text margin="0 0px 10px 0px" font="--base">
						сопровождение тендеров, отсев исполнителей.
					</Text>
				</List>
			</Box>
		</Section>
		<Section quarkly-title="Опыт" background="--trans3">
			<Override slot="SectionContent" flex-direction="row" md-display="block" />
			<Box max-width="750px">
				<Text font="--headline2" as="h2">
					Мой опыт
				</Text>
				<Box quarkly-title="Фриланс">
					<Text as="h3" font="--headline3" margin="5px 0px 5px 0px">
						Фриланс, 2004 – 2009
					</Text>
					<Text font="--base" margin="0px 0px 16px 0px">
						Обучался, занимался фриланс разработкой, реализовал веб-систему анкетирования в собственном учебном заведении в качестве дипломного проекта.
					</Text>
				</Box>
				<Box quarkly-title="Сеопульт">
					<Text as="h3" font="--headline3" margin="5px 0px 5px 0px">
						Сеопульт (Промопульт), 2009 – 2010
					</Text>
					<Text font="--base" margin="0px 0px 16px 0px">
						Занимался фуллстек разработкой клиентских сайтов в маркетинговом юните, а также разрабатывал внутренние инструменты и основой сайт маркетингового агентства.
					</Text>
				</Box>
				<Box quarkly-title="Прочее">
					<Text as="h3" font="--headline3" margin="5px 0px 5px 0px">
						Агентства и студии, 2010 – 2012
					</Text>
					<Text font="--base" margin="0px 0px 16px 0px">
						Занимался фуллстек разработкой различных сайтов и систем.{" "}
					</Text>
				</Box>
				<Box quarkly-title="Яндекс">
					<Text as="h3" font="--headline3" margin="5px 0px 5px 0px">
						Яндекс, 2012 – 2013
					</Text>
					<Text font="--base" margin="0px 0px 16px 0px">
						Занимался разработкой интерфейсов в Директе и системах модерации, проводил ежеквартальные конференции по фронт-енд разработке.
					</Text>
				</Box>
				<Box quarkly-title="Брайс">
					<Text as="h3" font="--headline3" margin="5px 0px 5px 0px">
						Брайс, 2013 – 2021
					</Text>
					<Text font="--base" margin="0px 0px 16px 0px">
						Организовал веб-продакшен входящий в 50 лучших разработчиков рейтинга Тэглайн.
					</Text>
				</Box>
				<Box quarkly-title="Мисс Россия">
					<Text as="h3" font="--headline3" margin="5px 0px 5px 0px">
						Мисс Россия, 2016 – 2018
					</Text>
					<Text font="--base" margin="0px 0px 16px 0px">
						Поддержка и доработка сайта, сопровождение интернет-голосования и трансляции финала.
					</Text>
				</Box>
				<Box quarkly-title="Afisha">
					<Text as="h3" font="--headline3" margin="5px 0px 5px 0px">
						Afisha.ru, 2016 – 2017
					</Text>
					<Text font="--base" margin="0px 0px 16px 0px">
						Разработка спецпроектов
					</Text>
				</Box>
				<Box quarkly-title="Renault">
					<Text as="h3" font="--headline3" margin="5px 0px 5px 0px">
						Renault Russia, 2017
					</Text>
					<Text font="--base" margin="0px 0px 16px 0px">
						Поддержка и доработка интранет-портала российского подразделения.
					</Text>
				</Box>
				<Box quarkly-title="Медси">
					<Text as="h3" font="--headline3" margin="5px 0px 5px 0px">
						Медси, 2017
					</Text>
					<Text font="--base" margin="0px 0px 16px 0px">
						Поддержка и доработка портала.
					</Text>
				</Box>
				<Box quarkly-title="SP">
					<Text as="h3" font="--headline3" margin="5px 0px 5px 0px">
						SmartPrice, 2018 – 2020
					</Text>
					<Text font="--base" margin="0px 0px 16px 0px">
						Контроль и ревью аутсорс-разработки, аналитика и разработка новой версии сайта, разработка CRM/ERP.
					</Text>
				</Box>
				<Box quarkly-title="Bank">
					<Text as="h3" font="--headline3" margin="5px 0px 5px 0px">
						Банк, 2020 – 2021
					</Text>
					<Text font="--base" margin="0px 0px 16px 0px">
						Разработка, ревью и внедрение стандартов разработки в продуктовой единице.
					</Text>
				</Box>
			</Box>
			<Box align-self="center" flex="1" md-display="none">
				<Image src="https://uploads.quarkly.io/604fb33c8db767001e87d6c0/images/327978_4699021639420_1752713608_o.jpg?v=2021-03-17T16:09:49.256Z" width="100%" />
			</Box>
		</Section>
		<Section quarkly-title="Отзывы и публикации">
			<Override slot="SectionContent" flex-direction="row" display="block" />
			<Text as="h2" font="--headline2">
				Публикации
			</Text>
			<Box
				display="grid"
				grid-template-columns="1fr 1fr"
				grid-gap="40px  20px"
				sm-display="block"
				quarkly-title="Статьи"
			>
				<Box display="flex" quarkly-title="VC министр" sm-margin="0px 0px 15px 0px">
					<Image width="100%" src="https://uploads.quarkly.io/604fb33c8db767001e87d6c0/images/safari-pinned-tab.svg?v=2021-04-28T17:49:37.972Z" max-width="64px" max-height="64px" />
					<Box margin="0px 0px 0px 10px">
						<Text as="h3" font="--headline3" margin="0 0px 0 0px">
							VC
						</Text>
						<Link
							rel="nofollow"
							href="https://vc.ru/flood/104148-o-cheshskom-ministre-zamolvite-slovo"
							target="_blank"
							font="--base"
							color="--dark"
						>
							О чешском министре замолвите слово
						</Link>
					</Box>
				</Box>
				<Box display="flex" quarkly-title="Inc" sm-margin="0px 0px 15px 0px">
					<Image width="100%" src="https://uploads.quarkly.io/604fb33c8db767001e87d6c0/images/inc.svg?v=2021-03-29T19:56:23.379Z" max-width="64px" max-height="64px" />
					<Box margin="0px 0px 0px 10px">
						<Text as="h3" font="--headline3" margin="0 0px 0 0px">
							Inc.
						</Text>
						<Link
							rel="nofollow"
							href="https://incrussia.ru/specials/qiwi-swim/"
							target="_blank"
							font="--base"
							color="--dark"
						>
							Уволиться из компании и уйти в свободное плавание.
						</Link>
					</Box>
				</Box>
				<Box display="flex" quarkly-title="ЗарплатаРу" sm-margin="0px 0px 15px 0px">
					<Image src="https://uploads.quarkly.io/604fb33c8db767001e87d6c0/images/180px.png?v=2021-03-29T19:59:01.493Z" max-width="64px" width="100%" max-height="64px" />
					<Box margin="0px 0px 0px 10px">
						<Text as="h3" font="--headline3" margin="0 0px 0 0px">
							Зарплата.ру
						</Text>
						<Link
							rel="nofollow"
							href="https://www.zarplata.ru/articles/2457493/"
							target="_blank"
							font="--base"
							color="--dark"
						>
							Работа в тестовом режиме
						</Link>
					</Box>
				</Box>
				<Box display="flex" quarkly-title="Секрет Фирмы" sm-margin="0px 0px 15px 0px">
					<Image width="100%" src="https://uploads.quarkly.io/604fb33c8db767001e87d6c0/images/logo-w-2.svg?v=2021-03-30T09:41:58.012Z" max-width="64px" max-height="64px" />
					<Box margin="0px 0px 0px 10px">
						<Text as="h3" font="--headline3" margin="0 0px 0 0px">
							Секрет фирмы
						</Text>
						<Link
							rel="nofollow"
							href="https://secretmag.ru/business/methods/chto-delat-esli-konkurent-snizhaet-ceny-a-vy-ne-mozhete-sebe-etogo-pozvolit.htm"
							target="_blank"
							font="--base"
							color="--dark"
						>
							Что делать, если конкурент снижает цены, а вы не можете этого сделать
						</Link>
					</Box>
				</Box>
				<Box display="flex" quarkly-title="Деловой Оиск" sm-margin="0px 0px 15px 0px">
					<Image src="https://uploads.quarkly.io/604fb33c8db767001e87d6c0/images/dobig.png?v=2021-03-30T09:43:35.997Z" max-width="64px" width="100%" max-height="64px" />
					<Box margin="0px 0px 0px 10px">
						<Text as="h3" font="--headline3" margin="0 0px 0 0px">
							Деловой Омск
						</Text>
						<Link
							rel="nofollow"
							href="https://newsomsk.ru/do/news/41540-reyting_saytov_kto_kruche_v_internete__opora_rossi/"
							target="_blank"
							font="--base"
							color="--dark"
						>
							Рейтинг сайтов: кто круче в интернете — «Опора России» или Союз предпринимателей?
						</Link>
					</Box>
				</Box>
			</Box>
			<Box max-width="1400px" margin="50px 0px 0px 0px" quarkly-title="Киви интервью">
				<Text as="h2" font="--headline2">
					Интервью
				</Text>
				<Components.Youtube
					videoId="do7TbFkYMCY"
					margin="0 auto 0px auto"
					display="block"
					height="50vw"
					width="90vw"
					max-width="100%"
					ratio="1.8"
					fullWidth
				/>
			</Box>
			<Box quarkly-title="Адекватный веб" margin="50px 0px 0px 0px">
				<Text as="h2" font="--headline2">
					Бесплатные разборы
				</Text>
				<Box lg-display="grid" lg-grid-template-columns="1fr 1fr" lg-grid-gap="25px">
					<Link
						href="https://www.youtube.com/watch?v=NKys_TyHg8E"
						display="inline-block"
						width="400px"
						height="225px"
						target="_blank"
						background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/604fb33c8db767001e87d6c0/images/%D0%94%D0%BE%D1%81%D1%82%D0%B0%D0%B2%D0%BA%D0%B0%20%D0%B5%D0%B4%D1%8B%20%D1%81%20%D0%B1%D0%BB%D0%B0%D0%B3%D0%BE%D1%82%D0%B2%D0%BE%D1%80%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D1%8C%D1%8E%20-%20YouTube%20%F0%9F%94%8A%202021-04-08%2020-52-35.jpg?v=2021-04-08T17:57:42.889Z) 0% 0% /auto 100% repeat scroll padding-box"
						margin="0px 25px 25px 0px"
						lg-margin="0px 0 25px 0px"
						lg-width="100%"
						sm-height="150px"
					/>
					<Link
						href="https://www.youtube.com/watch?v=d3jd9T_IB_s"
						display="inline-block"
						width="400px"
						height="225px"
						target="_blank"
						background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/604fb33c8db767001e87d6c0/images/kak2c.ru%20%E2%80%93%20r%D0%B0%D0%BA%20%D0%B7%D0%B0%D0%BF%D1%83%D1%81%D1%82%D0%B8%D1%82%D1%8C%20%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%BD%D0%B5%D1%82-%D0%BC%D0%B0%D0%B3%D0%B0%D0%B7%D0%B8%D0%BD%20%D0%B7%D0%B0%201%20%D0%B4%D0%B5%D0%BD%D1%8C%20kak2c.ru%20-%20YouTube%20%F0%9F%94%8A%202021-04-08%2020-51-42.jpg?v=2021-04-08T17:56:36.846Z) center/auto 100% no-repeat scroll padding-box"
						margin="0px 25px 25px 0px"
						lg-margin="0px 0 25px 0px"
						lg-width="100%"
						sm-height="150px"
					/>
				</Box>
			</Box>
		</Section>
		<Components.AnimationBg>
			<Section>
				<Override slot="SectionContent" flex-direction="row" align-items="center" sm-display="block" />
				<Formspree
					errorMessage="Произошла ошибка"
					completeText="Отправлено"
					endpoint="https://formspree.io/f/xqkwzonp"
					flex="0 1 40vw"
					padding="0px 5vw 0px 0px"
					enctype="multipart/form-data"
					margin="0px 0px 20px 0px"
				>
					<Text as="h3" font="--headline3" margin="16px 0px 0px 0px">
						Оставляйте запрос
					</Text>
					<Text font="--base" margin="0px 0px 16px 0px">
						Я свяжусь с вами в течение одного рабочего дня
					</Text>
					<Components.Recaptcha apikey="6Ld6oJ0aAAAAADHfEkRBhIz3qkygfM4NXZ5KgSSK" />
					<Input
						required
						as="textarea"
						display="block"
						placeholder="Ваш запрос"
						name="message"
						margin="5px 0 10px 0"
						width="100%"
					/>
					<Input
						name="email"
						placeholder="E-mail"
						margin-right="4px"
						font="--base"
						type="email"
						display="block"
						margin="5px 0 10px 0"
						width="100%"
					/>
					<Input
						name="phone"
						placeholder="Телефон"
						margin-right="4px"
						font="--base"
						type="tel"
						display="block"
						margin="5px 0 10px 0"
						width="100%"
					/>
					<Button font="--base" background="--color-gradient2" width="50%">
						Отправить
					</Button>
				</Formspree>
				<Box
					flex="1"
					text-align="right"
					sm-text-align="left"
					margin="0px 0px 20px 0px"
					md-text-align="left"
				>
					<Image src="https://media.giphy.com/media/26BRuo6sLetdllPAQ/giphy.gif" max-width="100%" />
				</Box>
			</Section>
		</Components.AnimationBg>
		<Section quarkly-title="Footer">
			<Override slot="SectionContent" flex-direction="row" />
			<Box flex="1">
				<Text margin="0 0px 0 0px">
					© 2017 – 2021
				</Text>
			</Box>
			<Box flex="1" text-align="right">
				<Components.UpToLike />
			</Box>
		</Section>
		<Components.Nocopy />
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<script place={"endOfHead"} rawKey={"606da4d00a6c172eb8a39865"}>
				{"(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\nnew Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\nj=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n})(window,document,'script','dataLayer','GTM-T56RZ9Z');"}
			</script>
		</RawHtml>
	</Theme>;
});