import React from 'react';
import atomize from "@quarkly/atomize";
const isDev = typeof window !== 'undefined' && window.QAPI && window.QAPI.mode === 'development';

const TagManager = props => {
	if (isDev) return null;
	return <noscript {...props}>
		<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T56RZ9Z" height="0" width="0" style={{
			'display': 'none',
			'visibility': 'hidden'
		}}></iframe>
	</noscript>;
};

export default atomize(TagManager)({
	name: "TagManager",
	normalize: false,
	mixins: true,
	description: {
		en: "TagManager"
	}
});