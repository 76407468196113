import React from 'react';
import atomize from "@quarkly/atomize";
import RecaptchaV3 from '@bryce.ru/quarkly-recaptcha';

const Recaptcha = ({
	apikey,
	...props
}) => {
	if (!apikey) return <div {...props}>
		Need apikey prop
	</div>;
	return <div {...props}>
		      
		<RecaptchaV3 devApiKey="6LcbDA4aAAAAAEFwTMx3btyp7cl35Um02aEDOMy0" prodApiKey={apikey} />
		    
	</div>;
};

export default atomize(Recaptcha)({
	name: "Recaptcha",
	normalize: true,
	mixins: true,
	description: {
		en: "Recaptcha"
	},
	propInfo: {
		apikey: {
			control: "input"
		}
	}
});